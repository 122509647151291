import { redirect } from 'react-router-dom';

/**
 * This function accepts a Map-representation of a URLSearchParams object and returns a new Map, with values of specified keys normalized accordingly
 *
 * @param {Object} params
 * @param {Map<string,string>} params.searchParamsMap - A Map-representation of a URLSearchParams object
 * @param {string[]} params.searchParamKeys - An array of keys that should be normalized per params.normalizationFunc
 * @param {function} [normalizationFunc] - A function that accepts an object with key/value specified and returns a new value (value.toLowercase() by default)
 * @returns {{normalized: Map<string,string>, isEquivalent: boolean}}
 */
const normalizeSearchParams = ({
    searchParamsMap,
    searchParamKeys,
    normalizationFunc = ({ value }) => value?.toLowerCase()
}) => {
    let isEquivalent = true;
    const normalizedSearchParamsMap = new Map(searchParamsMap);
    searchParamKeys.forEach(key => {
        if (searchParamsMap.has(key)) {
            const currentValue = searchParamsMap.get(key);
            const normalizedValue = normalizationFunc({ key, value: currentValue });
            normalizedSearchParamsMap.set(key, normalizedValue);
            if (normalizedValue !== currentValue) {
                isEquivalent = false;
            }
        }
    });
    return {
        normalized: normalizedSearchParamsMap,
        isEquivalent
    };
};

/**
 * This function accepts a Map-representation of a URLSearchParams object and returns a new Map, sorted accordingly
 *
 * @param {Object} params
 * @param {Map<string,string>} params.searchParamsMap - A Map-representation of a URLSearchParams object
 * @param {string[]} params.primaryOrder - An array of strings that represent known keys of a given sort order. These keys will always come first, as ordered, in the URL's search string.
 * @param {boolean} params.alphabetizeRemainder - If true, all remaining keys not present in param.primaryOrder will be sorted in alphabetical order. If false, they remain in the same relative order they would have otherwise been in with params.primaryOrder keys removed.
 * @returns {{sorted: Map<string, string>, isEquivalent: boolean}}
 */
const sortSearchParams = ({ searchParamsMap, primaryOrder = [], alphabetizeRemainder = true } = {}) => {
    const searchParamsKeys = [...searchParamsMap.keys()];
    const sortedKeys = [
        ...primaryOrder,
        ...(() => {
            const remainingKeys = searchParamsKeys.filter(key => !primaryOrder.includes(key));
            if (alphabetizeRemainder) {
                remainingKeys.sort((a, b) => a.localeCompare(b, 'en'));
            }
            return remainingKeys;
        })()
    ];
    const sortedSearchParamsMap = new Map();
    sortedKeys.forEach(key => {
        sortedSearchParamsMap.set(key, searchParamsMap.get(key));
    });
    return {
        sorted: sortedSearchParamsMap,
        isEquivalent: sortedKeys.toString() === searchParamsKeys.toString()
    };
};

/*
    Since the redirectWithModifiedSearchParams function issues a redirect to the same origin + path, if the
    equivalence check fails (and an actual redirect occurs), it will always be called twice, because the loader
    will be called again once the redirect completes. We can't avoid calling the loader twice, but by tracking our
    last-known redirect href, we can safely exit the 'redirectWithModifiedSearchParams' function early and avoid
    any expensive 'double processing'.

    Note that even if we bounce around to different routes configured with this function, we'll never exit
    prematurely because if any url matches the previous redirect, it's already optimized exactly how this
    function would have optimized it anyway.
 */
let lastRedirectionHref = null;

/**
 * This function's primary purpose is to satisfy the organization and display of any search params
 * (e.g. /route?searchParam=something) for a given route. We're currently doing this for a few reasons:
 *
 * - We have some older links "in the wild" that folks still use to access the site, and we don't want to
 *   break their experience or create inconsistent browsing behavior
 * - We have canonicalization requirements that must match a certain format (e.g, links distributed via sitemap)
 *   for optimized SEO performance.
 *
 * In general, we should only try to use this where necessary.
 *
 * @param params
 * @param {Object} [params.normalizationOptions] - An object of options passed to normalizeSearchParams
 * @param {Object} [params.sortOptions] - An object of options passed to sortSearchParams
 */
export const redirectWithModifiedSearchParams = ({ normalizationOptions, sortOptions }) => ({ request }) => {
    const currentURL = new URL(request.url);
    if (!currentURL.search || currentURL.href === lastRedirectionHref) {
        return null;
    }

    // filter out primary order options that are undefined
    const params = new URLSearchParams(currentURL.search);
    let primaryOrderOptions =
        sortOptions?.primaryOrder && sortOptions.primaryOrder.filter(option => params.has(option));

    let modifiedSearchParamsMap = new Map([...currentURL.searchParams.entries()]);
    const equivalence = [];
    if (normalizationOptions) {
        const result = normalizeSearchParams({
            searchParamsMap: modifiedSearchParamsMap,
            searchParamKeys: normalizationOptions.searchParamKeys
        });
        modifiedSearchParamsMap = result.normalized;
        equivalence.push(result.isEquivalent);
    }

    if (sortOptions) {
        const result = sortSearchParams({
            searchParamsMap: modifiedSearchParamsMap,
            primaryOrder: primaryOrderOptions,
            alphabetizeRemainder: sortOptions.alphabetizeRemainder
        });
        modifiedSearchParamsMap = result.sorted;
        equivalence.push(result.isEquivalent);
    }
    // Check if anything had changed and properly exit if not.
    if (equivalence.every(isEquivalent => isEquivalent)) {
        return null;
    }
    const newURL = new URL(request.url);
    newURL.search = `?${new URLSearchParams([...modifiedSearchParamsMap.entries()]).toString()}`;
    lastRedirectionHref = newURL.href;
    return redirect(newURL.href);
};
